import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ContentfulService {
  private baseURL = environment.production
    ? 'https://' +
      'cdn.contentful.com' +
      '/spaces/' +
      '5r8cf1e7tall' +
      '/entries?access_token=' +
      'TusmZi9dP7H8Z54b1pDxU3AJkf6OM0WnoGy9h6bJkFA'
    : 'https://' +
      'preview.contentful.com' +
      '/spaces/' +
      '5r8cf1e7tall' +
      '/entries?access_token=' +
      'BxfJmInEy-SHKH4ujPWOGqWQu4LqzvghcAh4ZlK839A';

  constructor(private http: HttpClient) {}

  getEntry(filter: string): Observable<any> {
    return this.http.get(this.baseURL + '&include=9' + filter);
  }

  getLinkedAsset(data: any, assetId: string): any {
    let foundAsset = {};

    for (let asset of data.includes.Asset) {
      if (asset.sys.id === assetId) {
        foundAsset = asset;
      }
    }

    return foundAsset;
  }

  getLinkedEntry(data: any, objectId: string): any {
    let foundEntry = {};

    for (let entry of data.includes.Entry) {
      if (entry.sys.id === objectId) {
        foundEntry = entry;
      }
    }

    return foundEntry;
  }
}
