import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-key-topics',
  templateUrl: 'key-topics.html'
})
export class KeyTopicsComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public text1 = '';
  public text2 = '';
  public text3 = '';

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();

    this.text1 = converter.makeHtml(this.fields.columnOne);
    this.text2 = converter.makeHtml(this.fields.columnTwo);
    this.text3 = converter.makeHtml(this.fields.columnThree);
  }
}
