import { Component } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../services/sessionstorage.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-content-page',
  templateUrl: 'content-page.html'
})
export class ContentPageComponent {
  public pageContent: any;
  public contentLoaded = false;
  public modules: any[] = [];

  constructor(
    public titleService: Title,
    public route: ActivatedRoute,
    public router: Router,
    private _contentService: ContentfulService,
    private meta: Meta
  ) {
    this.route.params.subscribe(params => {
      let route = params['id'] == null || params['id'] == '' ? 'home' : params['id'];

      _contentService.getEntry('&content_type=' + 'contentPage' + '&fields.pageId=' + route).subscribe(
        (data: any) => {
          if (data.items[0] != null) {
            if (data.items[0].fields.noFollowSearchEngines) {
              this.meta.addTag({ name: 'robots', content: 'noindex' }, true);
            }

            this.pageContent = data;
            this.contentLoaded = true;

            titleService.setTitle(route == 'home' ? 'CAA Broker Academy' : data.items[0].fields.title);

            this.modules = [];

            for (let module of this.pageContent.items[0].fields.modules) {
              let tempModule = this._contentService.getLinkedEntry(this.pageContent, module.sys.id);
              if (tempModule.fields) {
                this.modules.push(tempModule);
              }
            }
          } else {
            this.router.navigate(['/404']);
          }
        },
        err => {
          console.log(err);
        }
      );
    });
  }
}
