import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-module-picker',
  templateUrl: 'module-picker.html'
})
export class ModulePickerComponent {
  @Input() module: any;
  @Input() data: any;

  constructor() {}
}
