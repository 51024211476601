import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ApplicationService {
  private baseURL = environment.production ? 'https://' + 'mogamail.com/api/' : 'http://' + 'localhost:3000/api/';

  constructor(private http: HttpClient) {}

  sendApplication(formData: FormData) {
    let headers = {
      'content-type': 'multipart/form-data'
    };

    return this.http.post(this.baseURL + 'email', formData);
  }
}
