import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-facilitators',
  templateUrl: 'facilitators.html'
})
export class FacilitatorsComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public facilitators: any[] = [];
  public featuredFacilitator: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();

    for (let facilitator of this.fields.facilitators) {
      let fetched = this._contentService.getLinkedEntry(this.data, facilitator.sys.id);
      if (fetched.fields) {
        fetched.fields.image = this._contentService.getLinkedAsset(this.data, fetched.fields.image.sys.id);
        fetched.fields.id = fetched.fields.company
          .split(' ')
          .join('_')
          .toLowerCase();
        this.facilitators.push(fetched);
      }
    }

    this.featuredFacilitator = this._contentService.getLinkedEntry(this.data, this.fields.featuredFacilitator.sys.id);
    this.featuredFacilitator.image = this._contentService.getLinkedAsset(
      this.data,
      this.featuredFacilitator.fields.image.sys.id
    );
    this.featuredFacilitator.id = this.featuredFacilitator.fields.company
      .split(' ')
      .join('_')
      .toLowerCase();
    this.featuredFacilitator.bio = converter.makeHtml(this.featuredFacilitator.fields.smallBio);
  }
}
