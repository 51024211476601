import { Routes } from '@angular/router';

import { ContentPageComponent } from './content-page.component';

export const ContentPageRoutes: Routes = [
  { path: ':id', component: ContentPageComponent },
  { path: ':id/:id', component: ContentPageComponent },
  { path: ':id/:id/:id', component: ContentPageComponent },
  { path: '', component: ContentPageComponent }
];
