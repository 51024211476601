import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-countdown',
  templateUrl: 'countdown.html'
})
export class CountdownComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {}
}
