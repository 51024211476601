import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-facilitator-detail-list',
  templateUrl: 'facilitator-detail-list.html'
})
export class FacilitatorDetailListComponent implements OnInit, AfterViewInit {
  @Input() fields: any;
  @Input() data: any;
  public facilitators: any[] = [];
  public start = 0;
  public stop = 0;
  public pages: number[] = [];
  public currentPage = 0;
  public pageItemCount = 5;
  private fragment: string;

  constructor(
    private _contentService: ContentfulService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    console.log(this.fields);
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
    this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView();
          }
        }
      }
    });

    let converter = new showdown.Converter();

    for (let facilitator of this.fields.facilitators) {
      let fetched = this._contentService.getLinkedEntry(this.data, facilitator.sys.id);
      if (fetched.fields) {
        fetched.fields.image = this._contentService.getLinkedAsset(this.data, fetched.fields.image.sys.id);
        if (fetched.fields.extraImage != null) {
          fetched.fields.extraImage = this._contentService.getLinkedAsset(this.data, fetched.fields.extraImage.sys.id);
        }
        fetched.fields.longerBio = converter.makeHtml(fetched.fields.longerBio);
        fetched.fields.smallBio = converter.makeHtml(fetched.fields.smallBio);
        if (fetched.fields.company != null) {
          fetched.fields.id = fetched.fields.company
            .split(' ')
            .join('_')
            .toLowerCase();
        }
        this.facilitators.push(fetched);
      }
    }

    if (this.fields.paginate && this.facilitators.length > this.pageItemCount) {
      this.stop = this.pageItemCount;
      let numberOfPages = Math.floor((this.facilitators.length + this.pageItemCount - 1) / this.pageItemCount);
      this.pages = Array(numberOfPages)
        .fill(0)
        .map((x, i) => i);
    } else {
      this.stop = this.facilitators.length;
    }
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {}
  }

  public switchPage(page: number) {
    this.currentPage = page;
    this.start = page * this.pageItemCount;
    this.stop = (page + 1) * this.pageItemCount;

    const element = document.querySelector('#person-listing');
    if (element) {
      element.scrollIntoView();
    }

    return false;
  }
}
