import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-testimonials',
  templateUrl: 'testimonials.html'
})
export class TestimonialsComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image?: any;
  public reverse = '';
  public backgroundColor = '';
  public testimonial1 = '';
  public testimonial2 = '';
  public testimonial3 = '';

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();

    this.testimonial1 = converter.makeHtml(this.fields.testimonial1);
    this.testimonial2 = converter.makeHtml(this.fields.testimonial2);
    this.testimonial3 = converter.makeHtml(this.fields.testimonial3);
    console.log("testimonla 3", this.testimonial3)
    if (this.fields.image) {
      this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
    }
  }
}
