import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-reasons',
  templateUrl: 'reasons.html'
})
export class ReasonsComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image1: any;
  public image2: any;
  public image3: any;
  public subtitle: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();

    this.subtitle = converter.makeHtml(this.fields.subtitle);

    this.image1 = this._contentService.getLinkedAsset(this.data, this.fields.reason1Image.sys.id);
    this.image2 = this._contentService.getLinkedAsset(this.data, this.fields.reason2Image.sys.id);
    this.image3 = this._contentService.getLinkedAsset(this.data, this.fields.reason3Image.sys.id);
  }
}
