import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as showdown from 'showdown';

@Component({
	moduleId: module.id.toString(),
	selector: 'caa-video-gallery',
	templateUrl: 'video-gallery.html'
})
export class VideoGalleryComponent implements OnInit, AfterViewInit {
	@Input() fields: any;
	@Input() data: any;
	public text = '';
	public videos: any[] = [];
	public modalShown = false;
	public numberOfVideosToShow: number = 4;
	public readysReceived = 0;
	public videosReady = false;
	public wrapperShow = false;

	constructor(private _contentService: ContentfulService, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		let converter = new showdown.Converter();

		this.text = converter.makeHtml(this.fields.description);

		if (this.fields.videos != null) {
			for (var i = 0; i < this.fields.videos.length; i++) {
				let video = this.fields.videos[i];
				let videoItem = this._contentService.getLinkedEntry(this.data, video.sys.id);
				if (videoItem.fields) {
					let rawThumbnail = this._contentService.getLinkedAsset(this.data, videoItem.fields.thumbnail.sys.id);
					let rawTranscript;
					if (videoItem.fields.videoTranscript) {
						rawTranscript = this._contentService.getLinkedAsset(this.data, videoItem.fields.videoTranscript.sys.id);
					}

					videoItem.thumbnailurl = rawThumbnail.fields.file.url;
					videoItem.thumbnailAltText = rawThumbnail.fields.description;
					videoItem.iFrame = this.sanitizer.bypassSecurityTrustHtml('<iframe src="https://www.youtube.com/embed/' + videoItem.fields.youTubeId+ '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
					if (videoItem.fields.videoTranscript) {
						videoItem.transcriptLink = rawTranscript.fields.file.url;
					}
					videoItem.videoShown = false;
					this.videos.push(videoItem);
				}
			}
		}

		this.numberOfVideosToShow = this.fields.numberOfRows * 4;
	}

	ngAfterViewInit() {

	}

	public playVideo(video: any){
		this.hideAllVideos();
		video.videoShown = true;
	}

	public hideAllVideos() {
		for (let video of this.videos) {
			video.videoShown = false;
		}
	}
}
