import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  private suffix = '_data';

  set(key: string, object: string) {
    localStorage.setItem(key + this.suffix, object);
  }

  get(key: string): string {
    return localStorage.getItem(key + this.suffix);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
