import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-hero',
  templateUrl: 'hero.html'
})
export class HeroComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;
  public buttonFile: any;
  public textFile: any;
  public mobileImage: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
    this.mobileImage = this._contentService.getLinkedAsset(this.data, this.fields.mobileImage.sys.id);

    if (this.fields.fileDownload != null) {
      this.buttonFile = this._contentService.getLinkedAsset(this.data, this.fields.fileDownload.sys.id);
    }

    if (this.fields.textFileDownload != null) {
      this.textFile = this._contentService.getLinkedAsset(this.data, this.fields.textFileDownload.sys.id);
    }
  }
}
