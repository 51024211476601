import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-video',
  templateUrl: 'video.html'
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() fields: any;
  @Input() data: any;
  public videoShown = false;
  public image: any;
  public introText = '';
  public bodyText = '';
  public videoUrl: SafeHtml;
  public player: any;
  private videoReady = false;

  constructor(private _contentService: ContentfulService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.image = this._contentService.getLinkedAsset(this.data, this.fields.thumbnail.sys.id);

    let converter = new showdown.Converter();

    this.introText = converter.makeHtml(this.fields.introText);
    this.bodyText = converter.makeHtml(this.fields.bodyText);

    var theWindow: any = <any>window;

    if (theWindow.YT == null) {
      theWindow.onYouTubeIframeAPIReady = () => {
        this.initializeVideo();
      };
    } else {
      this.initializeVideo();
    }
  }

  ngAfterViewInit() {
    var theWindow: any = <any>window;
    const doc = theWindow.document;
    let playerApiScript = doc.createElement('script');
    playerApiScript.type = 'text/javascript';
    playerApiScript.src = 'https://www.youtube.com/iframe_api';
    doc.body.appendChild(playerApiScript);
  }

  public initializeVideo() {
    var theWindow: any = <any>window;
    this.player = new theWindow.YT.Player('video-container', {
      videoId: this.fields.youTubeId,
      playerVars: { cc_load_policy: 1 },
      events: {
        onReady: () => {
          this.videoReady = true;
          document.getElementById('video-container').setAttribute('aria-label', 'youtube video');
        },
        onStateChange: (e: any) => {
          if (e.data == 0) {
            this.videoShown = false;
          }
        }
      }
    });
  }

  public showVideo() {
    if (this.videoReady) {
      this.videoShown = true;
      this.player.playVideo();
    }
  }
}
