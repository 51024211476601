import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-rich-text',
  templateUrl: 'rich-text.html'
})
export class RichTextComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public text: string;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    this.text = documentToHtmlString(this.fields.body);
  }
}
