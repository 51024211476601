import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-hero-small',
  templateUrl: 'hero-small.html'
})
export class HeroSmallComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;
  public image2: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    console.log(this.fields);
    if (this.fields.image != null) {
      this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
    }

    if (this.fields.sideImage != null) {
      this.image2 = this._contentService.getLinkedAsset(this.data, this.fields.sideImage.sys.id);
    }
  }
}
