import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-image-with-text',
  templateUrl: 'image-with-text.html'
})
export class ImageWithTextComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;
  public reverse = '';
  public backgroundColor = '';
  public text = '';

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();
    this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);

    if (this.fields.backgroundColor == 'Blue') {
      this.backgroundColor = 'text-blue';
    }

    if (this.fields.backgroundColor == 'Gray') {
      this.backgroundColor = 'text-gray';
    }

    if (this.fields.imageOnRight) {
      this.reverse = 'reverse';
    }

    this.text = converter.makeHtml(this.fields.text);
  }
}
