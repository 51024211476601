import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-cta',
  templateUrl: 'cta.html'
})
export class CtaComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public backgroundColor = '';

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    if (this.fields.backgroundColor == 'Green') {
      this.backgroundColor = 'green-background';
    }
  }
}
