import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-page-title',
  templateUrl: 'page-title.html'
})
export class PageTitleComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {}
}
