import { Component, OnInit, Directive, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ContentfulService } from './services/contentful.service';

import { environment } from '@env/environment';

import * as showdown from 'showdown';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public _opened: boolean = false;
	public logo: any;
	public mobileLogo: any;
	public insuranceLogo: any;
	public itemitem: any;
	public navigationItems: any[] = [];
	public selectedItem: any = {};
	public globalLoaded = false;
	public mobileMenu = false;
	public menuHidden = true;
	public mobileMenuRootShown = true;
	public fields: any;
	public mobileSubMenuItems: any[] = [];
	public copyrightRichText: any;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _contentService: ContentfulService
	) {
		_contentService.getEntry('&content_type=globalContent').subscribe(
			data => {
				this.fillData(data);
				this.setSelectedNav();
				this.globalLoaded = true;
			},
			err => {
				console.log(err);
			}
		);

		router.events.subscribe(val => {
			if (val instanceof NavigationEnd) {
				const skipLinks = <HTMLElement>document.querySelector('#skip-links');
				this.setSelectedNav();
				this.closeMobileSubMenu();
				if (skipLinks) {
					skipLinks.focus();
				}
			}
		});
	}

	ngOnInit() {
		this.router.events.subscribe((s: any) => {
			if (s instanceof NavigationEnd) {
				const tree = this.router.parseUrl(this.router.url);
				if (tree.fragment) {
					const element = document.querySelector('#' + tree.fragment);
					if (element) {
						element.scrollIntoView();
					}
				}
			} else {
				// window.scrollTo(0, 0);
			}
		});
	}

	public closeMobileSubMenu() {
		let that = this;
		setTimeout(function() {
			for (let navItem of that.navigationItems) {
				if (navItem.subMenuOpen != null) {
					navItem.subMenuOpen = false;
				}
			}
			that.mobileMenuRootShown = true;
		}, 100);
		return false;
	}

	public toggleSubMenu(item: any) {
		let previousState = item.subMenuOpen;
		for (let navItem of this.navigationItems) {
			if (navItem.subMenuOpen != null) {
				navItem.subMenuOpen = false;
			}
		}

		item.subMenuOpen = !previousState;
		if (item.subMenuOpen) this.mobileMenuRootShown = false;
	}

	public toggleMobileMenu() {
		if (this.menuHidden) {
			this.menuHidden = false;
		} else {
			setTimeout(() => {
				this.menuHidden = true;
			}, 300);
		}

		setTimeout(() => {
			this.mobileMenu = !this.mobileMenu;

			if (this.mobileMenu == false) {
				this.closeMobileSubMenu();
			}
		}, 2);

		return false;
	}

	private checkSubNavMatch(navItem: any) {
		if (navItem.subPageItems != null) {
			for (let subNavItem of navItem.subPageItems) {
				if (subNavItem.pageId == this.router.url.substring(1)) return true;
			}
		}
		return false;
	}

	private setSelectedNav() {
		for (let navItem of this.navigationItems) {
			if (navItem.pageId == this.router.url.substring(1) || this.checkSubNavMatch(navItem)) {
				this.selectedItem = navItem;
				break;
			} else this.selectedItem = {};
		}
	}

	private fillData(data: any) {
		this.fields = data.items[0].fields;
		this.logo = this._contentService.getLinkedAsset(data, data.items[0].fields.logo.sys.id);
		this.insuranceLogo = this._contentService.getLinkedAsset(data, data.items[0].fields.insuranceLogo.sys.id);
		this.mobileLogo = this._contentService.getLinkedAsset(data, data.items[0].fields.mobileLogo.sys.id);

		let converter = new showdown.Converter();
		this.copyrightRichText = converter.makeHtml(this.fields.copyrightRichText.content[0].content[0].value);

		for (let navItem of this.fields.headerLinks) {
			let tempItem = this._contentService.getLinkedEntry(data, navItem.sys.id);
			if (tempItem.fields.subPages != null) {
				if(!tempItem.fields.dontShowInNav) {
					tempItem.fields.subPageItems = [tempItem.fields];
				} else {
					tempItem.fields.subPageItems = []
				}
				tempItem.fields.subMenuOpen = false;
				for (let subPage of tempItem.fields.subPages) {
					let tempSubItem = this._contentService.getLinkedEntry(data, subPage.sys.id);
					tempItem.fields.subPageItems.push(tempSubItem.fields);
				}
			}
			this.navigationItems.push(tempItem.fields);
		}
	}

	public focusSkipLink(id: any){
		const skipLinks = <HTMLElement>document.querySelector(id)
		if (skipLinks) {
			skipLinks.focus();
		}

		return false;
	}
}
