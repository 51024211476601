import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ng2-countdown-timer';
import { SidebarModule } from 'ng-sidebar';
// import { LightboxModule } from 'ngx-lightbox';
// import { Angular2PhotoswipeModule, LightboxAdapter } from 'angular2_photoswipe';
// import { CustomLightboxAdapter } from './services/lightboxadapter.service';
// import { SlickCarouselModule } from 'ngx-slick-carousel';

import { environment } from '@env/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Services
import { ContentfulService } from './services/contentful.service';
import { LocalStorageService } from './services/localstorage.service';
import { SessionStorageService } from './services/sessionstorage.service';
import { ApplicationService } from './services/application.service';

import { ModulePickerComponent } from './modules';

import { NoSanitizePipe } from './pipes/no-sanitize.pipe';

// Modules
import { CtaComponent } from './modules/cta';
import { VideoComponent } from './modules/video';
import { CountdownComponent } from './modules/countdown';
import { HeroComponent } from './modules/hero';
import { HeroSmallComponent } from './modules/hero-small';
import { ReasonsComponent } from './modules/reasons';
import { PageTitleComponent } from './modules/page-title';
import { DownloadCtaComponent } from './modules/download-cta';
import { KeyTopicsComponent } from './modules/key-topics';
import { FacilitatorsComponent } from './modules/facilitators';
import { FacilitatorDetailListComponent } from './modules/facilitator-detail-list';
import { ApplicationFormComponent } from './modules/application-form';
import { FaqList2Component } from './modules/faq-list-2';
import { AskYourselfComponent } from './modules/ask-yourself';
import { ImageWithTextComponent } from './modules/image-with-text';
import { TitleRichTextImageRight } from './modules/title-richtext-image-right';
import { RichTextComponent } from './modules/rich-text';
import { RichTextWithImageComponent } from './modules/rich-text-with-image';
import { ImageLeftTitleTextCtaComponent } from './modules/image-left-title-text-cta';
import { TopicListing } from './modules/topic-listing';
import { PhotoGalleryComponent } from './modules/photo-gallery';
import { VideoGalleryComponent } from './modules/video-gallery';
import { TestimonialsComponent } from './modules/testimonials';

// Pages
import { ContentPageComponent, ErrorPageComponent } from './layouts';

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    // LightboxModule,
    // Angular2PhotoswipeModule,
    SidebarModule.forRoot(),
    CountdownModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    ContentPageComponent,
    ErrorPageComponent,
    CtaComponent,
    VideoComponent,
    ApplicationFormComponent,
    DownloadCtaComponent,
    PageTitleComponent,
    KeyTopicsComponent,
    FaqList2Component,
    FacilitatorsComponent,
    FacilitatorDetailListComponent,
    AskYourselfComponent,
    ReasonsComponent,
    HeroComponent,
    HeroSmallComponent,
    CountdownComponent,
    ImageWithTextComponent,
    TitleRichTextImageRight,
    TopicListing,
    RichTextComponent,
    RichTextWithImageComponent,
    ImageLeftTitleTextCtaComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    TestimonialsComponent,
    ModulePickerComponent,
    // Pipes
    NoSanitizePipe
  ],
  providers: [
    ContentfulService,
    LocalStorageService,
    SessionStorageService,
    ApplicationService,
    // { provide: LightboxAdapter, useClass: CustomLightboxAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
