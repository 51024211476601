import { Component } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-error-page',
  templateUrl: 'error-page.html'
})
export class ErrorPageComponent {
  public pageContent: any;
  public contentLoaded = false;
  public modules: any[] = [];

  constructor(private _contentService: ContentfulService) {
    _contentService.getEntry('&content_type=' + 'contentPage' + '&fields.pageId=' + '404').subscribe(
      (data: any) => {
        if (data.items[0] != null) {
          this.pageContent = data;
          this.contentLoaded = true;

          this.modules = [];

          for (let module of this.pageContent.items[0].fields.modules) {
            this.modules.push(this._contentService.getLinkedEntry(this.pageContent, module.sys.id));
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
