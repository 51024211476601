import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-title-richtext-image-right',
  templateUrl: 'title-richtext-image-right.html'
})
export class TitleRichTextImageRight implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();
    if (this.fields.textNew) this.fields.textNew = converter.makeHtml(this.fields.textNew);

    if (this.fields.image) this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
  }
}
