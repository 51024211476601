import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
	moduleId: module.id.toString(),
	selector: 'caa-faq-list-2',
	templateUrl: 'faq-list-2.html'
})
export class FaqList2Component implements OnInit {
	@Input() fields: any;
	@Input() data: any;
	public faqs: any[] = [];

	constructor(private _contentService: ContentfulService) {}

	ngOnInit() {
		let converter = new showdown.Converter();

		let first = true;

		for (let faq of this.fields.faqs) {
			let fetchedFaq = this._contentService.getLinkedEntry(this.data, faq.sys.id);
			if (fetchedFaq.fields) {
				fetchedFaq.fields.text = converter.makeHtml(fetchedFaq.fields.text);
				if(first){
					fetchedFaq.open = true;
					first = false;
				} else {
					fetchedFaq.open = false;
				}
				this.faqs.push(fetchedFaq);
			}
		}
	}

	public toggleFaq(accordion: any){
		accordion.open = !accordion.open;

		(window as any).dataLayer.push({
			event: 'accordion',
			accordionTitle: accordion.fields.title
		});
	}
}
