import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentPageComponent } from './layouts/content-page/content-page.component';

const routes: Routes = [
  { path: 'facilitators', redirectTo: '', pathMatch: 'full' },
  { path: ':id', component: ContentPageComponent },
  { path: ':id/:id', component: ContentPageComponent },
  { path: ':id/:id/:id', component: ContentPageComponent },
  { path: '', component: ContentPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
