import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-image-left-title-text-cta',
  templateUrl: 'image-left-title-text-cta.html'
})
export class ImageLeftTitleTextCtaComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public title: string;
  public text: string;
  public buttonText: any;
  public buttonUrl: any;
  public image: any;

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    console.log('boolean-check', this.fields.hasOwnProperty('image'));

    if (this.fields.hasOwnProperty('image')) {
      this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
    }
  }
}
