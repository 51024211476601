import { Component, OnInit, Input, ViewChild, Renderer, ElementRef } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { ApplicationService } from '../../services/application.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-application-form',
  templateUrl: 'application-form.html'
})
export class ApplicationFormComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public applicationForm: FormGroup;
  public text = '';
  public fileName = 'Click browse to upload files';
  public formSubmitted = false;
  public formSubmitting = false;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private _contentService: ContentfulService,
    private fb: FormBuilder,
    private renderer: Renderer,
    private applicationService: ApplicationService
  ) {
    this.applicationForm = fb.group({
      name: ['', Validators.compose([Validators.required])],
      // company: ['', Validators.compose([Validators.required])],
      brokerage: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      file: ['', Validators.compose([Validators.required])]
    });
  }

  get f() {
    return this.applicationForm.controls;
  }

  public clickInput() {
    this.fileInput.nativeElement.click();
  }

  public fileEvent(fileInput: any) {
    let file = fileInput.target.files[0];
    this.fileName = file.name;
  }

  public sendData() {
    if (this.applicationForm.valid) {
      let inputEl: HTMLInputElement = this.fileInput.nativeElement;
      const formData: FormData = new FormData();
      formData.append('name', this.f.name.value);
      // formData.append('brokerage_company', this.f.company.value);
      formData.append('brokerage_name', this.f.brokerage.value);
      formData.append('email', this.f.email.value);
      formData.append('contact_number', "");
      formData.append('file', inputEl.files[0]);

      this.formSubmitting = true;
      this.applicationService.sendApplication(formData).subscribe((data: any) => {
        if (data.success) {
          this.formSubmitting = false;
          this.formSubmitted = true;
        } else {
          this.formSubmitting = false;
          alert('Something went wrong, please try again.');
        }
      });
    }
  }

  ngOnInit() {
    let converter = new showdown.Converter();

    this.text = converter.makeHtml(this.fields.text);
  }
}
