import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-topic-listing',
  templateUrl: 'topic-listing.html'
})
export class TopicListing implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;
  public topics: any[] = [];

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    for (let topic of this.fields.topics) {
      let topicEntry = this._contentService.getLinkedEntry(this.data, topic.sys.id);
      if (topicEntry.fields) {
        topicEntry.fields.image = this._contentService.getLinkedAsset(this.data, topicEntry.fields.image.sys.id);
        if (topicEntry.fields.buttonFileDownload != null) {
          topicEntry.fields.buttonFile = this._contentService.getLinkedAsset(
            this.data,
            topicEntry.fields.buttonFileDownload.sys.id
          );
        }

        if (topicEntry.fields.linkFileDownload != null) {
          topicEntry.fields.textFile = this._contentService.getLinkedAsset(
            this.data,
            topicEntry.fields.linkFileDownload.sys.id
          );
        }
        this.topics.push(topicEntry);
      }
    }
  }
}
