import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { Image } from 'angular2_photoswipe';
import * as showdown from 'showdown';

@Component({
	moduleId: module.id.toString(),
	selector: 'caa-photo-gallery',
	templateUrl: 'photo-gallery.html'
})
export class PhotoGalleryComponent implements OnInit, AfterViewInit {
	@Input() fields: any;
	@Input() data: any;
	public text = '';
	public galleryImages: Image[] = [];
	public numberOfPhotosToShow: number = 2;

	constructor(private _contentService: ContentfulService) {}

	ngOnInit() {
		let converter = new showdown.Converter();

		this.text = converter.makeHtml(this.fields.description);

		this.numberOfPhotosToShow = this.fields.numberOfRows * 2;

		if (this.fields.images != null) {
			let counter = 0;
			for (let image of this.fields.images) {
				let rawImage = this._contentService.getLinkedAsset(this.data, image.sys.id);
				if (rawImage.fields) {
					let img = new Image();
					img.largeUrl = rawImage.fields.file.url;
					// img.height = rawImage.fields.file.details.image.height;
					// img.width = rawImage.fields.file.details.image.width;
					// img.id = counter;
					// img.size = `${img.width}x${img.height}`;
					// img.thumbUrl = rawImage.fields.file.url + '?fit=thumb&f=center&h=180&w=250';
					if (rawImage.fields.description != null && rawImage.fields.description != '') {
						img.description = rawImage.fields.description;
						// img.author = rawImage.fields.description;
					}
					if(counter < this.numberOfPhotosToShow){
						this.galleryImages.push(img);
					}
					counter++;
				}
			}
		}
	}

	ngAfterViewInit() {
		// document.getElementById('sampleId').removeAttribute('id');
		//
		// let thumbnails: any = document.querySelectorAll("ngp-gallery-item > a")
		// for (let thumbnail of thumbnails) {
		// 	thumbnail.setAttribute("aria-label", "this photo viewer is disabled for screen readers.");
		// 	thumbnail.setAttribute("aria-hidden", "true");
		// 	thumbnail.addEventListener('keydown', function(event: any) {
		// 		if (event.keyCode === 13) {
		// 			event.preventDefault();
		// 			event.stopPropagation();
		// 			return false;
		// 		}
		// 	})
		// }
	}
}
