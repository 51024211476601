import { Component, OnInit, Input } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import * as showdown from 'showdown';

@Component({
  moduleId: module.id.toString(),
  selector: 'caa-download-cta',
  templateUrl: 'download-cta.html'
})
export class DownloadCtaComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;
  public image: any;
  public file: any;
  public text = '';

  constructor(private _contentService: ContentfulService) {}

  ngOnInit() {
    let converter = new showdown.Converter();

    this.image = this._contentService.getLinkedAsset(this.data, this.fields.image.sys.id);
    this.file = this._contentService.getLinkedAsset(this.data, this.fields.fileDownload.sys.id);
    this.text = converter.makeHtml(this.fields.text);
  }
}
